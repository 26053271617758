.pageLogine{
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#topLogin{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 35VW;
}

#downLogin{
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 33VW;
}

#noteLogin{
    position: absolute;
    bottom:2.5vw;
    width: 100%;
    text-align: center;
    font-size: 17px;
}

.inputsContainer{
    width: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.inputsContainer a {
    border-radius: 50%;
}

.containerInputs{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.inputcontainer {
    position: relative;
    width: 100%;
}

.inputcontainer input{
    width: 100%;
    padding: 16px;
    font-size: 14px;
}

.inputcontainer p{
    position: absolute;
    top: -15px;
    left: 15px;
    background-color: white;
    padding: 5px;
    color: #021D6A;
    font-size: 14px;
    font-weight: 500;
}

.eye {
    position: absolute;
    top: 19px;
    right: 20px;
    cursor: pointer;
}

.error{
    color: red;
    font-size: 14px;
    font-weight: 500;
}

#button{
    cursor: pointer;
    width: 100%;
    padding: 1.3vw;
    border: none;
    border-radius: 10px; 
    color: white;
    font-size: 20px;
    font-weight: 500;
    background: linear-gradient(to bottom right, rgb(114,237,242), rgb(50,72,133));
}
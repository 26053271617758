
.inputsAddProduct{
    display: flex;
    flex-direction: column;
    gap: 2.6vw;
    padding: 3vw 0px;
}

.addContainerDashboard{
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
    padding-left: 4vw;
}

.headerAddProduct{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerAddProduct #addProductTxt{
    font-size: 1.5vw;
    font-weight:600 ;
}

.headerAddProductCA #textGris{
    background-color: #E9E9E9;
    padding: .5vw 1.3vw;
    border-radius:8px ;
    transition: .3s;
    text-decoration: none;
    color: black;
}

.headerAddProductCA #textGris:hover{
    background-color: #6b6b6b;
    color: white;
}

.headerAddProductCA p{
    font-size: 1.12vw;
    font-weight: 500;
    cursor: pointer;
}

.headerAddProductCA div p {
    color: white;
}

.headerAddProductCA{
    display: flex;
    align-items: center;
    gap: 1.5vw;
}

.headerAddProductCA div img{
    transition: .5s;
}

.headerAddProductCA div{
    display: flex;
    padding: .5vw 1.3vw;
    border-radius:8px ;
    gap: .3vw;
    justify-content: center;
    align-items: center;
    background-color: #0E88E0;
    cursor: pointer;
}

.headerAddProductCA div:hover img{
    transform: translateX(5px);
}



.inputsAddProduct{
    margin: 0px 6vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.inputsAddProduct .inputImages{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5vw;
    align-items: center;
    border: 2px dashed #0E88E0;
    border-radius: .7vw;
}

.inputsAddProduct .inputAdd{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputsAddProduct .imagesProducts{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow-x: scroll;
    gap: 15px;
}

.inputsAddProduct .inputImages p {
    font-size: 1.1vw;
    font-weight: 400;
}

.inputsAddProduct .inputImages p strong{
    color: #0E88E0;
    cursor: pointer;
}

.inputContainerAdd{
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

.inputContainerAdds{
    display: flex;
    width: 100%;
    gap: 2vw;
}

.elementsLigne{
    display: flex;
    justify-content: space-between;
    gap: 3vw;
}


.elementsRow{
    width: 100%;
}

.imputContainer{
    flex: 1;
    position: relative;
    display: flex;
    gap: 1.5vw;
    margin-top: 25px;
}

.imputContainerP{
    margin-top: 25px;
    position: relative;
}

 .insideInput{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex: 1;
}

 .textInput {    
    position: absolute;
    top: -15px;
    left: 15px;
    background-color: white;
    padding: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #0E88E0;
}

 .imputContainer input,textarea{
    width: 100%;
    padding: 16px; 
    font-size: 14px;
}

.selectPrinterType{
    width: 100%;
    padding: 16px;
    font-size: 14px; 
}

.elementsLigneInside{
    display: flex;
}

#PromotionTxt{
    border: 2px solid black ;
    border-radius: 5px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: .5vw;
    font-size: 1.1vw;
    font-weight: 600;
}

#Promotion{
    width: 5.5vw;
}

.prixPromo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.prixPromo p{
    font-weight: 600;
}

.prixPromo .prix{
    color: #0E88E0;
    border: none;
    text-align: end;
}

.imageContainerInputs{
    position: relative;
}

.inputFile{
    width: 500px;
    height: 500px;
    object-fit: cover;
    display:flex;
    align-items: center;
}


.addDelete{
    position: absolute;
    padding: 0px 3px;
    border-radius: 5px;
    top: 75%;
    left: 45%;
    display: flex;
    gap: 3px;
    background-color: rgba(255, 255, 255, 0.708);
}

.imagesProducts .addDelete .add{
    font-size: 1.9vw;
    font-weight: 400;
    cursor: pointer;
}
.imagesProducts .addDelete .delete{
    cursor: pointer;
}
.imagesProducts hr {
    width: 50%;
    outline: none;
}




.imageProduct{
    width: 100%;
    height: 70%;
    object-fit: cover;
}

.viewImage{
    display: none;
}



/* design de add Categorie*/

.addSous{
    margin-top: 10px;
    display: flex;
    font-weight: 500;
    color: #0E88E0;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
}

.suppcat{
    position: absolute;
    top: 27%;
    right: 8px;
    cursor: pointer;
}

.brandsContainer{
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: .6vw;
}

.brandContainer{
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 30px;
    background-color: #0E88E0;
    color: white;
    padding: 4px 12px;
}

.brandContainer img{
    cursor: pointer;
}
.commandPanelContainer{
    margin: 2.5vw 6.5vw;
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

.commandPrixContainer{
    display: flex;
}

.commandContainer{
    flex: 3.5;
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.prixContainer{
    flex: 1;
    display: flex;
    justify-content: center;

}

.prixContainer div{
    display: flex;
    flex-direction: column;
    gap: .6vw;
}

#panelTitle{
    font-size: 1.6vw;
    font-weight: 550;
}

#prixPanel{
    font-size: 1.3vw;
    font-weight: 550;
    color: #54A1BF;
}

#totalTitle{
    font-size: 1.3vw;
    font-weight: 700;
}

#annulerCommand{
    border-radius: 8px; /* Apply border radius */
    box-shadow: 0px 4px 20px rgba(0,0,0,.08);
    padding: .6vw 1.3vw;
    letter-spacing: 2px;
    border-radius: .5vw;
    font-size: 1.2vw;
    font-weight: 350;
    text-align: center;
    transition: .5s transform ease;
    cursor: pointer;
}

#commanderPanel{
    padding: .6vw 1.3vw;
    letter-spacing: 2px;
    border-radius: .5vw;
    font-size: 1.2vw;
    font-weight: 350;
    color: white;
    cursor: pointer;
    transition: .5s transform ease;
    display: flex;
    align-items: center;
    gap: .5vw;
    background: linear-gradient(to bottom right, rgb(114,237,242), rgb(50,72,133));
}

#commanderPanel img{
    width: 2vw;
}

#annulerCommand:hover{
    transform: translateY(-5px);
}
#commanderPanel:hover{
    transform: translateY(-5px);
}

#noData{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
}


#noData img{
    width: 12vw;
}

#noData p{
    font-size: 1.3vw;
    font-weight: 500;
}




.inputesCommandContainer{
    display: flex;
    flex-direction: column;
    gap: 2vw;
    width: 70%;
}

.inputesCommandContainer div{
    display: flex;
    gap: 2vw;
}

.inputesCommandContainer .leftInputCommand{
    flex: 1;
}

.inputesCommandContainer .rightInputCommand{
    flex: 1;
}

.inputCommand{
    padding: 1vw 2vw;
    border-radius: 8px; /* Apply border radius */
    box-shadow: 0px 4px 20px rgba(0,0,0,.08);
    border: none;
    font-size: 1vw;
    font-weight: 400;
}

@media screen and (max-width: 950px) {

    .commandPrixContainer{
        flex-direction: column;
        align-items: start;
        gap:10px;
    }

    .inputesCommandContainer{
        gap:10px;
    }

    .commandContainer{
        width: 100%;
    }

    
#panelTitle{
    font-size: 15px;
}

#prixPanel{
    font-size: 12px;
}

#totalTitle{
    font-size: 12px;
}

#annulerCommand{
    font-size: 13px;
}


#commanderPanel{
    font-size: 13px;
}


.inputesCommandContainer div{
    flex-direction: column;
    gap: 15px;
}

.inputesCommandContainer{
    width: 100%;
}

.inputCommand{
    padding: 15px 20px;
    font-size: 11px;
}

  }
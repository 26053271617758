.containerEspace{
    position: relative;
}

#imageEspace{
    position: absolute;
    right: 0px;
    top: 10px;
    height: 50vw;
}

.insideContainerEspace{
    display: flex;
    flex-direction: column;
    gap: 2vw;
    justify-content: center;
    text-align: center;
    padding: 3vw 27vw;
}

.inputEspaceContainer{
    display: flex;
    justify-content: center;
}

.inputEspace{
    position: relative;
    width: 50%;
    align-items: center;
}

.inputEspace input {
    padding: 10px;
    width: 100%;
    border: 1.5px solid rgb(192, 192, 192);
    border-radius: 8px;
}



.divDownlaod{
    cursor: pointer;
    top: 12%;
    right: .5vw;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 2.2vw;
    width: 2.2vw;
    background: linear-gradient(to bottom right, #72EDF2, #324885);
}

.divDownlaod img{
    height: 1.5vw;
    transition: .3s height;
}

.divDownlaod img:hover{
    height: 1.7vw;
}

#titleEspace{
    font-size: 2vw;
    font-weight: 600;
}

#descriptionEspace{
    font-size: 1.2vw;
    font-weight: 400;
}

.contacterEspaceContainer {
    display: flex;
    justify-content: center;
}

#contacterEspace{
    cursor: pointer;
    border: 2px solid #0E88E0;
    border-radius: 8px;
    width: fit-content;
    color: rgb(77, 77, 77);
    text-decoration: none;
    padding: 10px 15px;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: 'Poppins', sans-serif;
}


.headerComponant{
    margin: 0px 6.5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logoContainer{
    flex:.7 ;
}

#logo img{
    height: 5vw;
}

.headerList{
    flex: 1.3;
    display: flex;
    list-style: none ;
    align-items: center;
    justify-content: flex-end;
    gap: 1.1vw;
}

.headerList .Links{
    font-size: .88vw;
    font-weight: 450;
    text-decoration: none;
    padding: .45vw .8vw;
    border-radius: 10px;
}

.headerList select{
    border: 1.6px solid black;
}

.active-link{
    color: white;
    background: linear-gradient(to bottom right, rgb(114,237,242), rgb(50,72,133));
}

.nonActive{
    color: black;
}

#menuHeader{
    height: 20px;
    display: none;
}

#closeMenuHeader{
    display: none;
}

@media screen and (max-width: 950px) {
    #logo img{
        height: 50px;
    }

    .headerComponant{
        position: relative;
        padding: 0px;
    }

    .headerList{
       position: absolute;
       font-size: 20px;
       display: flex;
       flex-direction: column;
       justify-content: center;
       gap: 25px;
       z-index: 4;
       top: 22vh;
       height: 50vh;
       background-color: white;
       width: 100%;
       border-radius: 20px;
       box-shadow: 0px 4px 20px rgba(0,0,0,.1);
    }

    #menuHeader{
        display: block;
        cursor: pointer;
    }

    .Links option{
        font-size: 10px;
    }

    .headerList .Links{
        font-size: 20px;
    }

    #closeMenuHeader{
        display: block;
        height: 20px;
        position: inherit;
        top: 30px;
        right: 20px;
    }
    
}

.dashboardContainer{
    display: flex;
    gap: 2vw;
}

.allDashboards{
    width: 68%;
    margin-top: 3vw;
}




.allProductHome{
    display: flex;
    flex-direction: column;
    margin: 2vw 11vw;
    gap: 2.5vw;
}


.mapContainer{
    margin: 0px 6.5vw;
    display: flex;
    flex-direction: column;
    gap: 1.2vw;
}

.mapText{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#mapTxt1{
    font-size: 1.6vw;
    font-weight: 550;
}

#mapTxt2{ 
    text-decoration: underline;
    color: #021D6A;
}

#map{
    height: 45vw;
    width: 100%;
    border-radius:20px ;
    overflow: hidden;
    box-shadow: 0px 4px 20px rgba(0,0,0,.2);
}

#map iframe{
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
}

.sponsor{
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 30px;
} 

.sponsor img{
    width: 100%;
}

.sponsorText{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .3vw;
    top: 4vw;
}

#sponsorOur{
    color: #021D6A;
    font-size: 1.4vw;
    font-weight: 400;
}

#sponsoPartner{
    font-size: 1.7vw;
    font-weight: 500;
}

#sponsorTxt{
    font-weight: 300;
    font-size: 1.1vw;
}

.beforeFooter{
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}


#imgBeforeFooter{
    height: 31vw;
    width: 90%;
}

.beforeFooterText{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    left: 10vw;
    width: 42vw;
}

.beforeFooterText p{
    color: white;
}

#beforeFooterTitle{
    font-weight: 700;
    font-size: 3.5vw;
}

#beforeFooterdesc{
    font-size: 1.4vw;
    font-weight: 400;
    width: 80%;
}

#beforeFooterContact{
    background: linear-gradient(to bottom right, rgb(114,237,242), rgb(50,72,133));
    color: white;
    letter-spacing: 2px;
    font-size: .95vw;
    border-radius: 4px;
    font-weight: 600;
    padding: 10px 12px;
    width:fit-content;
    cursor: pointer;
}

.panelContainer{
    position: fixed;
    height: 65px;
    width: 65px; 
    border-radius:50% ;
    right: 3vw;
    top: 50px;
    background-color: white;
    border: 3;
}

.panel{
    box-shadow: 0px 4px 20px rgba(0,0,0,.2);
    position: relative;
    border-radius:50% ;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#panelImg{ 
    width: 50%;
    height:50%;
    border-radius:50% ;
    transition: all .4s ease;
}

.panel:hover #panelImg{
    width: 65%;
    height:65%;
}

#panelTxt{
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2;
    width: 23px;
    height: 23px;
    border-radius: 11.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom right, rgb(114,237,242), rgb(50,72,133));
    color: white;
}

.NosMarque{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3vw;
}

.nosmarque{
    font-size: xx-large;
    font-weight: 600;
}

@media screen and (max-width: 650px) {
    #mapTxt1{
        font-size: 15px;
    }
  }
  

.image-gallery {
    position: relative;
    width: 100%; /* Adjust this to your preferred height */
    overflow: hidden;
}

.image-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.image-container img {
    width: 100%;
}

.navigation-indicators {
    display: flex;
    position: absolute;
    top: 30vw;
    left: 50%;
    padding: 4px 2px;
    border-radius: 10px;
    background: linear-gradient(to bottom right, rgb(114,237,242), rgb(50,72,133));
    transform: translateX(-50%);
}

.indicator {
    width: 1vw;
    height: 1vw;
    border-radius: 50%;
    background-color: #f3f3f38d;
    margin: 0 5px;
}

.active {
    background-color: #ffffff;
}

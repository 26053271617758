.detailProductContainer{
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.headerShouwingCar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#nameProductHeader{
    font-size: 1.6vw;
    font-weight: 600;
}

#backHeader{
    padding: .5vw 2vw;
    background-color: #E9E9E9;
    border-radius: 8px;
    font-size: 1vw;
    font-weight: 600;
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.productDetailCountainer{
    padding: 0px 12vw;
}

.productDetail{
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.productDetail .imageContainerShowCar{
    width: 100%;
    height: 35vw;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgb(169, 169, 169);
    border-radius: 1vw;
}

.productDetail .imageContainerShowCar img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fichPrice{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
}

.fichPrice a{
    color: #F26522;
    font-size: 1.3vw;
}

.priceShowingCar{
    display: flex;
    align-items: center;
    gap: 1.2vw;
}

#priceBefore{
    font-size: .8vw;
    text-decoration: line-through;
    color: #787878;
}

#priceAfter{
    font-size: 1.5vw;
    color: #F26522;
}

.descriptionContainer{
    display: flex;
    flex-direction: column;
    gap: .8vw;
}

.descriptionTitle{
    font-size: 1.1vw;
    font-weight: 600;
}

.descriptionContent{
    display: flex;
    flex-direction: column;
    gap: 0.65vw;
    padding-bottom: 1vw;
    word-wrap: break-word;
    font-size: 1vw;
    font-weight: 400;
}
 .Product{
    border: 2px solid #54A1BF;
    border-radius: 20px; /* Apply border radius */
    box-shadow: 0px 4px 20px rgba(0,0,0,.1);
    padding: 0vw;
}

  .imagesProduct{
    position: relative;
}

  .imagesProduct .productimg{
    border-radius: 20px;
    width: 23vw;
    height: 18vw;
    object-fit: cover;
}
 
  .imagesProduct .promotionimg{
    position: absolute;
    top: 0px;
    left: 0px;
    border-top-left-radius: 19px;
    height: 8vw;
}

  .imagesProduct p{
    position: absolute;
    top: 1.5vw;
    left: 1vw;
    color: white;
    font-size: 1vw;
    font-weight: 600;
}

  .infoProduct{
    margin: 1vw;
}

  .infoProduct{
    font-size: 1.15vw;
}

  .infoProduct .nameProduct{
    font-weight: 500 ;
    text-decoration: none;
    color: black;
    transition: .3s ease ;
    cursor: pointer;
    width:fit-content;
}

  .infoProduct .nameProduct:hover{
    text-decoration:underline;
    color: #021D6A;
}


  .infoProduct .priceAdd{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

  .infoProduct .price{
    font-weight: 550 ;
    color: #021D6A;
}

  .infoProduct .add{
    display: flex;
    align-items: center;
    gap: 1vw;
    padding: .6vw 1vw;
    border-radius: 7px;
    background: linear-gradient(to bottom right, rgb(114,237,242), rgb(50,72,133));
    color: white;
    cursor: pointer;
    transition: all .4s ease;
}

  .infoProduct .add:hover{
    gap: .1vw;
}


.promotionProductPrice{
    color: #000000;
    font-weight: 550 ;
}

.promotionProductBorder{
    border: none;
    border-radius: 20px; /* Apply border radius */
    box-shadow: 0px .2vw 1.2vw rgba(0,0,0,.1);
}

.addIcon{
    width: 2vw;
}


@media screen and (max-width: 950px) {

  .imagesProduct .productimg{
    width: 40vw;
    height: 38vw;
}  

.imagesProduct p{
  font-size: 15px;
  left: 5px;
  top: 10px;
}

.infoProduct{
  font-size: 10px;
}

.infoProduct .nameProduct{
  font-size: 9px;
}

.add img{
  width: 20px;
}

}



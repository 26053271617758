.searchBar_container{
    display: flex;
    justify-content: center;
    background-color: white;
    margin: 0% 18%;
    padding: 1% 2%;
    border-radius:15px;
    color: #333333;
    margin-top: .8vw;
    box-shadow: 0px 0px 10px rgb(192, 192, 192);
}

.search_bar{
    display: flex;
    flex-direction: column;
}

.title_search {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: .3vw  ;
    
}

.title_search img{
    height: .85vw;
}


.catScat{
    display: flex;
    align-items: center;
}

.searchbarSelect{
    padding: .5vw .3vw;
    border-radius: 8px;
    border: 2px solid #54A0BF;
    font-size: .9vw;
    font-weight: 500;
    color: #021D6A;
}

.form{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: .25vw;
}

.list_search{
    display: flex;
    align-items: center;
    width: 100%;
}

.list_search .prix{
    display: flex;
    align-items: center;
    gap: .6vw;
}

.search_bar p{
    font-size: 1vw;
    font-weight: 600;
}

div ul li{
    display: flex;
    flex-direction: column;
    gap: .5vw;
}

div ul li input{
    background: #E9E9E9;
    border: none;
    outline: none;
    border-radius: 4px;
}

div ul li  #type{
    padding: .5vw .99vw;
    width: 16vw;
    font-size: .9vw;
}

div ul li select{
    border: none;
    outline: none ;
}

select option{
    font-weight: 200;
}

.list_search .prix p{
    font-size: .9vw;
    font-weight: 400;
}


div ul li  #prix{
    padding: .1vw .2vw;
    width: 7vw;
    font-size: .9vw;
}

div .searchBarButton{
    padding: .5vw 1.5vw;
    background: linear-gradient(to bottom right, rgb(114,237,242), rgb(50,72,133));
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    font-size: .9vw;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
    cursor: pointer;
    margin-left: 4.5vw;
}

.hrSearch{
    width: 2vw;
    height: 0px;
    transform: rotate(90deg);
}

div .searchBarButton:hover{
    color: #000000;
}

.EtatSearch {
    display: flex;
    gap: 1vw;
    margin-left: 0.5vw;
    justify-content: center;
    align-items: center;
}


.radiocheck a {
    cursor: pointer;
    padding: 0vw 0.4vw;
    border-radius: 8px;
    background-color: transparent;
    font-weight: 500;  
    text-decoration: none;
}

.is_not{
    color: #808080;
}


.Choose{
    margin-top: 1.2vw;
}


.firstSectionPropos{
    height: 35vw;
    width: 100%;
    background: url("../assets/propsBanner.svg");
    background-size: cover;
    color: white;
    padding: 0px 6.5vw;
    display: flex;
    align-items: center;
}

.allTextFirstSectionPropos{
    display: flex;
    flex-direction: column;
    gap: 55px;
}

.textOnePropos{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.insideTextOnePropos{
    font-size: 3vw;
    font-weight: 800;
    max-width: 72%;
    display: flex;
    align-items: center;
    gap: 1vw;
}

.blueSide{
    height: 7vw;
    width: 8px;
    border-radius: 8px 0px 0px 8px ;
    background-color: #0E88E0;
}

.discriptionPropos{
    max-width: 50%;
    font-size: 1.3vw;
    font-weight: 300;
    padding: 0px;
}

.buttonContainerProps{
    display: flex;
    gap: 30px;
}

.buttonContainerProps a,.buttonContainerProps p{
    padding: 10px 15px;
    border-radius: 8px ;
    cursor: pointer;
}

#nosProduit{
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    background-color: #0E88E0;
    font-size: 1.1vw;
}

#contacterNousPropos{
    color: #0E88E0;
    border: 2px solid #0E88E0;
    background-color: white;
    letter-spacing: 1px;
    font-size: 1.1vw;
}


.secondSectionPropos{
    padding: 3vw 5vw;
    display: flex;
    gap: 4vw;
}

.contentOneSecondSectionPropos{
    flex: 1;

    position: relative;
}

.contentTwoSecondSectionPropos{
    flex: 1;
    height: 40vw;
    display: flex;
    flex-direction: column;
    gap: 30px;

}

#mainImagePropos{

    overflow: hidden;
    height: 88%;
    width: 92%;
    border-radius: 12px;
}

#mainImagePropos img{
    object-position: -30px;
    object-fit: cover;
}


#secondImagePropos{
    position: absolute;
    right: 0px;
    bottom: 0px;
}

#secondImagePropos img{
    height: 19vw;
} 

.titesNosServicePropos{
    display: flex;
    flex-direction: column;
    gap: 13px;
}


.serviceIconPropos{
    display: flex;
    align-items: center;
    font-size: 1.1vw;
    gap: 1.3vw;
}

#nosService{
    font-size: 1.6vw;
    font-weight: 600;
}


.itemServiceProps{
    display: flex;
    gap: 20px;
}

.itemServiceProps div{
    height: max;
    width: 5px;
    background-color: #D2EAFC;
    border-radius: 0px 6px 6px 0px;
}

.itemServiceProps p{
    font-size: 1.02vw;
    font-weight: 400;
    padding: 3px 0px;
}

.itemsServiceProps hr {
    margin: 18px 0px;
    height: 1px ;
    outline: none;
    border: none;
    background-color:  rgba(133, 133, 133, 0.433);
}

.bannerImage{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2vw;
}

.ligneNosClientPropos{
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0px 6vw;
}

.ligneNosClientPropos p{
    position: absolute;
    background-color: rgb(255, 255, 255);
    top: -12px;
    padding: 0px 10px;
    font-size: 1.1vw;
    color: rgb(78, 78, 78);
    font-weight: 500;
}

.ligneNosClientPropos hr{
    height: 1px ;
    outline: none;
    border: none;
    background-color:  rgba(133, 133, 133, 0.433);
    width: 100%;
} 


.paddingPartenaire{
    padding-top: 3vw;
    width: 100%;
    display: flex;
    justify-content: center;
}
.commandLineContainer{
    display: flex;
    border-radius: 20px; /* Apply border radius */
    box-shadow: 0px 4px 20px rgba(0,0,0,.08);
    padding: 1vw 1.5vw;
}


.commandLineImgName{
    display: flex;
    flex: 1;
    gap: 1.5vw;
}

.commandLineImgName img{
    width: 5vw;
    border-radius: .45vw;
    object-fit: cover;
}

.commandLineImgName div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap:  0.4vw;
}

.commandLineQuantPrixDelete{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quantityPrice{
    display: flex;
}

.nameProduct{
    font-size: 1.2vw;
    font-weight: 550;
}

.sousCatProduct{
    font-size: 1vw;
    font-weight: 450;
}

.quantityPrice{
    display: flex;
    align-items: center;
    gap:  .8vw;
    border-radius: 5px;
    box-shadow: 0px 4px 20px rgba(0,0,0,.08);
}

.changeQuantiteComand{
    border: 1px solid #54A1BF;
    padding: .3vw 0.3vw;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
}

.prixCommnadProduct{
    font-size: 1vw;
    font-weight: 550;
}

#linkEye{
    display: flex;
    width: fit-content;
    height: fit-content;
}

.vueDeleteCommand{
    display: flex;
    gap: .5vw;
}

#removeVue{
    cursor: pointer;
}

@media screen and (max-width: 950px) {

    .nameProduct{
        font-size: 11px;
    }

    .sousCatProduct{
        font-size: 11px;
    }

    .prixCommnadProduct{
        font-size: 11px;
    }
  }
.homeInformation{
    display: flex;
    justify-content: space-between;
    margin: 5px 6.5vw;
}


.firstInfo, .thirdInfo{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
 
.firstInfo, .secandInfo{
    gap: .2vw;
}

.secandInfo{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}



.thirdInfo{
    align-items: center;
    font-size: 1.2vw;
    font-weight: 700;
    background: linear-gradient(to bottom right, rgb(114,237,242), rgb(50,72,133));
    -webkit-background-clip: text; /* For Safari */
    background-clip: text;
    color: transparent; /* Hide the actual text */
}

.Info{
    display: flex;
    align-items: center;
    gap: .5vw;
}

.imgInfo{
    height: 1.7vw;
}

.pInfo{
    font-size: .95vw;
    font-weight: 600;
}

.fourthInfo{
    display: flex;
    align-items: center;
    gap: .5vw;
}

.fourthInfo img{
    height: 4vw;
}

.fourthInfo p{
    font-size: 1.06vw;
    font-weight: 600;
}

.fourthInfo #wilayas{
    background: linear-gradient(to bottom right, rgb(114,237,242), rgb(50,72,133));
    -webkit-background-clip: text; /* For Safari */
    background-clip: text;
    color: transparent; /* Hide the actual text */
}


@media screen and (max-width: 950px) {

    .homeInformation{
        justify-content: space-around;
    }

    
    .thirdInfo{
    font-size: 15px;
    }

    .pInfo{
        font-size: 10px;
    }


    .theirdInfo{
        display: none;
    }

    .fourthInfo{
        display: none;
    }

}










.allProduct{
    display: flex;
    flex-direction: column;
    gap: .7vw;
}


.allProduct .headerListProduct{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.allProduct .headerListProduct .seeAll{
    color: #021D6A;
    font-weight: 450;
}

.allProduct .headerListProduct p {
    font-weight: 550;
}

.allProduct .containerProducts{
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    align-items: center;
}

.spaceBetween{
    display: flex;
    justify-content: space-between;
}

.gap{
    display: flex;
    justify-content: flex-start;
    gap: 4vw;
}

.noData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1vw;
    width: 100%;
    gap: .8vw;
}

.noData img{
    width: 10vw;
}

@media screen and (max-width: 950px) {

    .containerProducts{
        gap: 20px;
        overflow: scroll;
        scrollbar-width: none;
    }
    

  }
.headerSearch{
    height: 17vw;
    background: linear-gradient(to bottom right, rgb(114,237,242, 0.5), rgb(50,72,133,0.5));
}



.containerPageProduct{
    display:flex;
    gap: 3vw;
    margin: 2vw 2vw 2vw 0vw;
}

.ListCategorieProduct{
    flex: 1;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 4px 20px rgba(0,0,0,.1);
    border-radius: 0px .8vw .8vw 0px;
    height: fit-content;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.productContainerProduct{
    flex: 4;
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
}


.itemProduct{
    display: flex;
    align-items: center;
    padding: 1vw ;
    gap: 8px;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0,0,0,.1);
}

.itemProduct div{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: 1.04vw;
    font-weight: 500;
}

.nameCategoryProduct:hover{
    text-decoration: underline 1.5px;
}

.nameCategoryProduct{
    cursor: pointer;
}
 
.productContainerProductFiltred {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 2.2vw; /* Adjust the gap according to your design */
    align-items: center;
    justify-content: space-between;
}

.noDataProduct{
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 1vw;
    width: 100%;
    gap: .8vw;
}

.noDataProduct img{
    width: 10vw;
}

@media screen and (max-width: 750px) {

    .ListCategorieProduct{
        display: none;
    }

    .containerPageProduct{
        display: flex;
        flex-direction: column;
        margin: 2vw 11vw;
        gap: 2.5vw;
    }
    
  }
  

.sideBarCoContainer{
    width: 24%;

}

.sidedBarContainer{
    width: 24%;
    position: fixed;
    height: 100vh;
    box-shadow: 0px 4px 20px rgba(0,0,0,.2);
    display: flex;
    flex-direction: column; 
    align-items: center;
    gap: .5vw;
    overflow-y: scroll;
}
 
.sidedBarContainer ul li {
    list-style: none;
}

.sidedBarContainer a{
    text-decoration: none;
    color: black;
}

.sidedBarContainer hr{
    width: 95%;
    outline: none;
}

.sidedBarContainer #logoSideBar{
    height: 100px;
}

.sidedBarContainer .sideBar{
    width: 100%;
    padding: 1vw 1.5vw;
    display: flex;
    flex-direction: column; 
    gap: 1.5vw;
    height: 100%;
}

.sidedBarContainer .sideBarList{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.item{
    display: flex;
    align-items: center;
    padding: 1vw ;
    gap: 8px;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0,0,0,.1);
    cursor: pointer;
}

.iconsSideBar{
    height: 2vw;
    width: 2vw;
}

.arrowIcon{
    height: 14px;
    cursor: pointer;
}

.item div{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: 1.04vw;
    font-weight: 500;
}

.ListInsideList{
    margin: 10px 3vw;
    justify-content: center;
    flex-direction: column;
    gap: .6vw;
}

.ListInsideList li{
    gap: 1vw;
    width: fit-content;
    border-radius: 20px;
    cursor: pointer;
    overflow: hidden;
}

.ListInsideList li .linkInsideList{
    display: flex;
    padding: 1px 8px;
    gap: .4vw;
    font-size: .9vw;
    font-weight: 500;
}

.addcat_deco{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5vw;
    justify-content: flex-end;
    align-self: flex-end;
}

.addCat{
    cursor: pointer;
    border: 2px solid #0E88E0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .6vw;
    border-radius: 10px;
    padding: .8vw;
}

.addCat p{
    font-size: 1.2vw;
    font-weight: 600;
    color: #0E88E0;
}


.deconnecter{
    display: flex;
    gap: 5px;
    background-color: #0E88E0;
    align-items: center;
    justify-content: center;
    padding: 14px 0px;
    font-size: 1.3vw;
    font-weight: 550;
    color: white;
    border-radius:10px ;
    cursor: pointer;
}

.activee-link{
    background-color: #0E88E0;
    color: white;
}

.notActibe{
    color: rgb(113, 113, 113);
    background-color: rgb(230, 230, 230);
}

.active-linkk{
    background-color: rgba(14, 137, 225, 0.35);
}

.noActive-linkk{
    background-color: rgba(255, 255, 255, 0.35);
} 


.visible{
    display: flex;
}

.hidden{
    display: none;
}
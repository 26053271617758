.detailProductClient{
    margin: 2.5vw 6.5vw;
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

#detailProductName{
    font-size: 1.6vw;
    font-weight: 550;
}

.detailProductinfo{
    display: flex;
    gap: 3vw;
}

.detailProductinfoImgs{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.detailProductinfoImgs #principale{
    width: 100%;
    height: 30vw;
    border-radius: 1vw;
    box-shadow: 0px 4px 20px rgba(0,0,0,.2);
    object-fit: cover;
}

.detailProductinfoImgs .otherImages{
    display: flex;
    justify-content: space-between;
}

.detailProductinfoImgs .otherImages img{
    width: 22%;
    border-radius: .8vw;
    cursor: pointer;
    box-shadow: 0px 4px 20px rgba(0,0,0,.2);
    transition: .2s transform ease;
}

.detailProductinfoImgs .otherImages img:hover{
    transform: translateY(-6px);
}


.detailProductinfoData{
    flex: 1.3;
    display: flex;
    flex-direction: column;
    gap: 3.5vw;
}

.descContainer{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    font-size: 1.15vw;
    font-weight: 400;
}

#descTitle{
    font-size: 1.3vw;
    font-weight: 550;
}

#containerDescriptionP{
    display: flex;
    flex-direction: column;
    gap: .7vw;
}

.fichetechPrixPanel{
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
}

.fichetechPrixPanel a{
    font-size: 1.2vw;
    font-weight: 600;
    color: #021D6A;
}

.pricePanel{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    align-items:flex-end;
}

.price{
    display: flex;
    gap: 2vw;
    align-items: center;
}

#prixTT{
    font-size: 1vw;
    font-weight: 700;
    color: #676767;
    text-decoration: line-through;
}

#prixPromo{
    font-size: 1.8vw;
    font-weight: 700;
    background: linear-gradient(to bottom right, rgb(114,237,242), rgb(50,72,133));
    -webkit-background-clip: text; /* For Safari */
    background-clip: text;
    color: transparent; /* Hide the actual text */
}

.addTopanel{
    display: flex;
    align-items: center;
    gap: .7vw;
    border-radius: .5vw;
    border: 2.5px solid #539DBD;
    padding: .7vw 1.1vw;
    cursor: pointer;
}

.addTopanel:hover img{
    transform: translateX(5px);
}

.addTopanel img{
    width: 2vw;
    transition: .2s transform ease-in;
}

.addTopanel p{
    font-size: 1.2vw;
}